<template>
  <div>
    <div class="header">
      <v-img lazy-src="@/assets/images/news-homepage1.png" style="max-height:3.4rem;max-width:100%;"
        src="@/assets/images/news-homepage1.png">

        <!-- <template>
          <div class="header-text">新闻资讯</div>
        </template> -->
      </v-img>
    </div>
    <div class="classify">
      <div class="classify-btn" @click="getNewsBtn('all')">全部新闻</div>
      <div class="classify-btn" @click="getNewsBtn('1')">新闻快讯</div>
      <div class="classify-btn" @click="getNewsBtn('2')">技术更新</div>
      <div class="classify-btn" @click="getNewsBtn('3')">合作事项</div>
    </div>
    <div></div>
    <div class="content">
      <div class="car-table">
        <v-row v-if="newsList.rows">
          <v-col v-for="(item, index) in newsList.rows" :key="index">
            <v-hover v-if="item.newsType = 5" style="background-color:#fef3f1;display: flex;padding: 0.2rem;">
              <template v-slot:default="{ hover }">
                <v-card :loading="loading" :class="`mx-auto my-0 elevation-${hover ? 24 : 6
                  } transition-swing`">
                  <div style="width: 30%;cursor: pointer;">
                    <v-img v-if="item.newsPic" height="3rem" class="car-img" :lazy-src="imgUrl + item.newsPic"
                      :src="imgUrl + item.newsPic" @click="reserve(item.newsId)">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-3"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <div v-else style="height:1.99rem;line-height: 1.69rem;" class="car-img">
                      <img src="@/assets/images/imgerror.png" width="100%" />
                    </div>
                  </div>

                  <v-col @click="reserve(item.newsId)" class="card-text">
                    <v-row class="news-title">{{ item.newsTitle }}</v-row>
                    <v-row class="news-content">{{ item.newsContent }}</v-row>
                    <v-row class="news-date">
                      {{ item.createTime ? item.createTime.split(" ")[0] : '' }}
                    </v-row>
                  </v-col>

                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
        <div v-else class="empty-data">
          <span>暂无数据</span>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="pagination clearfix">
        <v-pagination v-if="newsList.total" v-model="queryParams.pageNum"
          :length="Math.ceil(newsList.total / queryParams.pageSize)" :total-visible="7"
          @input="getnewsList()"></v-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getNews } from "@/api/index";
import store from '@/store'
export default {
  name: "News",
  components: {},
  beforeCreate() { },
  created() {
    if (this.grid == "xl") {
      this.queryParams.pageSize = 8;
    } else {
      this.queryParams.pageSize = 6;
    }
    this.getnewsList();
  },
  beforeDestroy() { },
  filters: {},
  data() {
    return {
      imgUrl: process.env.VUE_APP_BASE_API,
      queryParams: {
        pageNum: 1,
        pageSize: 6,
      },
      loading: false,
    };
  },
  computed: {
    grid: {
      get() {
        return window.localStorage.getItem("grid");
      },
    },
    newsList:{
      get(){
        return this.$store.state.news.newsList
      }
    }
  },
  methods: {
    async getnewsList() {
      if(this.$route.params.hash){
        this.queryParams.newsType=parseInt(this.$route.params.hash)
      }
      this.loading = true;
      let res = await getNews(this.queryParams);
      if (res.code == 200) {
        this.$store.commit("news/setNewList", res);
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    },
    async getNewsBtn(data) {
      this.loading = true;
      let params = this.queryParams
      if (data === 'all') {
        params.newsType = null
      } else {
        params.newsType = parseInt(data)
      }
      let res = await getNews(params);
      if (res.code == 200) {
        this.$store.commit("news/setNewList", res);
        // this.newsList = res;
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    },
    reserve(id) {
      this.$router.push({
        path: "/news/" + id,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  margin-top: 0vw;

  .header-text {
    text-align: center;
    color: #fff;
    font-size: 0.5rem;
    font-weight: 500;
    line-height: 3.46rem;
    -webkit-background-clip: text;
  }
}

.car-img {
  border-radius: 0.12rem;
  // box-shadow: 0px 0.03rem 0.06rem 0.01rem rgba(0, 0, 0, 0.16);
}

.card-text {
  margin: 0.1rem 0.4rem;
  font-family: 思源黑体 CN;
  cursor: pointer;

  .news-title {
    font-size: 0.26rem;
    line-height: 0.3rem;
  }

  .news-content {
    margin-top: 0.3rem;
    font-size: 0.21rem;
    height: 1.6rem;
    line-height: 0.3rem;
  }

  .news-date {
    font-size: 0.21rem;
    margin-top: 0.5rem;
    padding-left: 5.69rem;
    // padding-left: 30vw;
    color: rgb(106, 106, 106);
  }
}

.classify {
  display: flex;
  padding-left: 5.8rem;

  .classify-btn {
    cursor: pointer;
    margin: 2vw 1vw;
    padding: 0.09rem;
    text-align: center;
    color: rgb(15, 15, 104);
    font-family: 思源黑体 CN;
    font-size: 0.24rem;
    font-weight: 400;
    height: 0.45rem;
    width: 1.55rem;
    box-sizing: border-box;
    background-color: rgb(255, 249, 225);
    border: 1px solid rgb(255, 222, 195);
    border-radius: 30px;
  }
}

.content {
  padding-top: 0.34rem;
  background: #ffffff;

  // box-shadow: inset 0px -0.03rem 0.06rem 0.01rem rgba(0, 0, 0, 0.16);
  .car-table {
    width: 60%;
    margin: 0 auto;
  }

  .empty-data {
    text-align: center;
    font-size: 0.2rem;
    line-height: 3rem;
  }
}

.footer {
  width: 60%;
  margin: 0 auto;
  margin-top: 0.3rem;
  height: 2rem;
}

.clearfix:after {
  content: "020";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  /* 触发 hasLayout */
  zoom: 1;
}

.pagination {

  // float: right;
  ::v-deep .v-pagination {
    button {
      font-size: 0.14rem;
      margin: 0.1rem;
    }
  }
}

.clear {
  clear: both;
}
</style>
